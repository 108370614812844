$dza-green: #4db286;
$dza-gray-light: #f7f7fa;
$dza-gray: #f4f4f8;
$dza-darker: #dfdfe8;
$dza-dark-gray: #989ca3;
$dza-darkest: #727273;
$dza-black: #1b1a1b;
$dza-red: darkred;
$font-base: 'Open Sans', sans-serif;

// ----------- Circle buttons -----------
// --------------------------------------
$circle-btn: 36px;
$circle-btn-lg: 41px;
$circle-btn-padding: 5px;

[class^='circle-btn-'],
[class*=' circle-btn-'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $circle-btn;
  height: $circle-btn;
  border-radius: calc($circle-btn / 2);
  padding: $circle-btn-padding;
  color: $dza-black;

  &:not(.no-hover) {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  > .icon {
    font-size: calc($circle-btn / 2);

    &.icon-settings {
      font-size: 2 + calc($circle-btn / 2);
    }
  }

  span {
    font-family: $font-base;
    line-height: calc($circle-btn - ($circle-btn-padding * 2));
    font-weight: 600;
  }

  .country-flag {
    width: 32px;
    height: auto;
    margin-right: 6px;
  }

  .slash {
    padding: 0 5px 0 3px;
  }
}

a[class^='circle-btn-'],
a[class^='circle-btn-']:hover,
a[class^='circle-btn-']:active,
a[class*=' circle-btn-'],
a[class*=' circle-btn-']:hover,
a[class*=' circle-btn-']:active {
  text-decoration: none;
  color: $dza-black;
}

[class^='circle-btn-lg-'],
[class^='circle-btn-lg'],
[class*=' circle-btn-lg-'],
[class*=' circle-btn-lg'] {
  width: $circle-btn-lg;
  height: $circle-btn-lg;
  border-radius: calc($circle-btn-lg / 2);

  > .icon {
    font-size: calc($circle-btn-lg / 2);
  }

  > span {
    line-height: calc($circle-btn-lg - ($circle-btn-padding * 2));
  }
}

[class^='circle-btn-'][class*='-wide '],
[class^='circle-btn-'][class$='-wide'],
[class*=' circle-btn-'][class*='-wide '],
[class*=' circle-btn-'][class$='-wide'] {
  width: auto;
  padding-left: 14px;
  padding-right: 13px;
}

.circle-btn-gray {
  background-color: $dza-gray;
}

.circle-btn-darker {
  background-color: $dza-darker;
}
.circle-btn-dark-gray {
  background-color: $dza-dark-gray;
}
.circle-btn-darkest {
  background-color: $dza-darkest;
}

.circle-btn-white {
  background-color: white;
}

.circle-btn-outline {
  background-color: white;
  border: 2px solid $dza-dark-gray;
}

.circle-btn-green {
  background-color: $dza-green;

  .icon {
    color: white;
  }
}

.circle-btn-red {
  background-color: $dza-red;

  .icon {
    color: white;
  }
}

@media print {
  .circle-btn-gray,
  .circle-btn-darker,
  .circle-btn-dark-gray,
  .circle-btn-darkest,
  .circle-btn-white,
  .circle-btn-green,
  .circle-btn-red {
    background-color: transparent;
    color: #000;
  }

  .circle-btn-outline {
    background-color: transparent;
    border: 0;
    color: #000;
  }
}

// --------------------------------------

.form-check-input:checked {
  background-color: $dza-green !important;
  border-color: #fff;
}

.btn-primary {
  background: $dza-green;
  background-color: $dza-green !important;
  color: #fff;
  border: none !important;
}

button:hover,
.btn-primary:hover {
  opacity: 0.9;
}

.submit-button {
  background: $dza-green;
  border-radius: 3px;
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-top: 28px;
  height: 48px;
  line-height: 48px;
  outline: 0;
  border: 0;
  color: white;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.015em;
}

button:disabled,
button[disabled],
.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

@media (min-width: 768px) {
  .submit-button {
    height: 48px;
    line-height: 48px;
  }
}

.custom-select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  background-color: $dza-gray;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;

  color: #1b1a1b;

  border: none;
}

.custom-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  color: #1b1a1b;
}

.custom-label-thin {
  font-weight: 400;
}

@media (max-width: 768px) {
  .mobile-hide {
    display: none;
  }
  .mobile-left {
    float: left;
  }
  .mobile-right {
    float: right;
  }
}

.header-button {
  cursor: pointer;
  float: right;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  padding: 4px 20px;
  outline: 0;
  border: 0;
  border-radius: 21px;
  background: $dza-gray;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 auto;
  min-height: 42px;
}

@media (min-width: 768px) {
  .header-button {
    margin: 0 0 0 auto;
  }
}

.heading-label {
  font-family: 'Exo 2' !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 38px !important;
  line-height: 46px !important;

  color: #1b1a1b !important;
  margin: 0 !important;
}

.heading-sublabel {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px !important;

  color: #1b1a1b !important;
}

.gray-datazauta-table {
  background: $dza-gray;
  border-radius: 3px;
  padding: 22px;
}

.vehicle-spz-label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #1b1a1b;
}

.operator-table-label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* or 133% */
  margin-left: 2.8em;

  color: #1b1a1b;
}

.vehicle-type-label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.6rem;
  /* identical to box height, or 150% */

  color: $dza-darkest;
}

.vehicle-stats-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.6rem;
  /* identical to box height, or 175% */
  color: #1b1a1b;
}

.vehicle-stats-value-wrapper {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.6rem;
  /* identical to box height, or 95% */
  color: #1b1a1b;
}

.vehicle-stats-value-wrapper .vehicle-stats-value-unit {
  color: $dza-darkest;
  font-size: 0.65em;
  margin-left: 0.5em;
  display: inline-block;
  height: 22px; // vehicle-stats-value-wrapper

  &.tight {
    margin-left: 0.075rem;
  }
}

.unit-wrapper {
  color: $dza-darkest;
  font-size: 0.75em;
  margin-left: 0.5em;
}

.vehicle-stats-percent-wrapper {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1.375rem;
  color: $dza-darkest;
  margin-bottom: 0.5rem;
}

.vehicle-stats-percent-wrapper-red {
  color: #e5474c;
}

.vehicle-stats-percent-wrapper-green {
  color: #4c7e2c;
}

.back-button-wide {
  box-sizing: border-box;

  height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: #1b1a1b;

  display: inline-block;
  background: #fff;
  border: 2px solid #d6d6dd;
  border-radius: 21px;
  padding: 9px 25px;
  cursor: pointer;
}

.flex-container-and-center {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-wrapper-white-background {
  background: #ffffff;
}

.table-headers {
  border: none;
  background: $dza-gray;
}

.table-headers tr {
  border-left: 18px solid $dza-gray;
  border-right: 18px solid $dza-gray;
}

.table-headers tr th {
  text-align: left;
}

.table-headers th {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #1b1a1b;
  border: none;
  border-bottom: none !important;
}

.inner-white-table {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.6rem;
  background: #ffffff;
  border-radius: 3px;
}

.inner-white-table tr {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
}

.inner-white-table td {
  border-top: none;
  border-bottom: 2px solid $dza-gray;
}

.inner-white-table .opened-expandable-row td {
  border-bottom: none;
}

.inner-white-table tr:last-child td {
  border-bottom: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

@media (max-width: 768px) {
  .mobile-left {
    float: left;
  }

  .mobile-right {
    float: right;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently 
                                  supported by Chrome, Edge, Opera and Firefox */
}

.loading-overlay-visible {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-y: hidden;
}

.left-filter-visible {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  //overflow-y: hidden; //Was disabled by request
}

.dialog-visible {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.cdk-overlay-container {
  z-index: 20000;
}

.cdk-global-overlay-wrapper {
  z-index: 20111;
}

.mat-success {
  background: $dza-green;
  background-color: $dza-green;
  color: #fff;
}

.full-width {
  width: 100%;
}

.editable-note {
  font-style: italic;
  cursor: pointer;

  .icon {
    color: $dza-darker;

    &.editable-note-already {
      display: none;
    }
  }

  &:hover,
  &:active {
    .icon {
      color: $dza-dark-gray;

      &.editable-note-already {
        display: initial;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.map-responsive {
  position: relative;
  height: 100%;
  width: 100%;
}

.angular-google-map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.map-responsive .map-container {
  width: 100% !important;
}

@media (min-width: 768px) {
  .no-padding-desktop {
    padding: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.no-padding {
  padding: 0 !important;
}

input:focus-visible {
  outline: none;
}

.mat-checkbox-label {
  white-space: initial;
  caret-color: #000;
}
* {
  caret-color: rgba(0, 0, 0, 0);
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.relative {
  position: relative;
}

.flex-center {
  display: flex;
  align-items: center; /* vertical centering if flex-direction: row */
  justify-content: center; /* horizontal centering if flex-direction: row */
}

.table-responsive tr td {
  vertical-align: middle !important;
}

.table-responsive-align-top tr td {
  vertical-align: top !important;
}

@media (max-width: 768px) {
  .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    width: 100% !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: spinner 1s infinite step-start;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  8.3% {
    transform: rotate(30deg);
  }

  16.6% {
    transform: rotate(60deg);
  }

  24.9% {
    transform: rotate(90deg);
  }

  33.2% {
    transform: rotate(120deg);
  }

  41.5% {
    transform: rotate(150deg);
  }

  50% {
    transform: rotate(180deg);
  }

  58.3% {
    transform: rotate(210deg);
  }

  66.6% {
    transform: rotate(240deg);
  }

  74.9% {
    transform: rotate(270deg);
  }

  83.2% {
    transform: rotate(300deg);
  }

  91.5% {
    transform: rotate(330deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.highcharts-root,
.highcharts-container {
  overflow: visible !important;
}

.clearfix {
  clear: right;
}

.hide {
  display: none !important;
}

.marker-custom-text {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 60%) 0px 1px 4px -1px;
  border: 1px solid #b1b1b1;
  font-weight: bold;

  padding: 2px 4px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: auto;
  width: auto;

  margin-top: 13px;
  overflow: hidden;
}

.numbered-marker {
  padding-bottom: 10px;
}

.table-white-header-gray-rows {
  thead {
    background: #fff;
  }
  tbody {
    background: $dza-gray;
    tr {
      border-radius: 3px;
      border-left: none;
      border-right: none;
      background: $dza-gray;
      td {
        border-bottom: 7px solid #fff;
      }
    }
  }
}
/* 
.flag-wrapper-and-iso { 
  display: flex; 
  align-items: center; // vertical centering if flex-direction: row  
  justify-content: center; // horizontal centering if flex-direction: row 
  text-decoration: none; 
  &:hover { 
    color: initial; 
  } 
 
  .flag-wrapper img { 
    width: 32px; 
    height: auto; 
  } 
 
  .iso-wrapper { 
    padding-left: 0.5em; 
    padding-top: 2px; 
    text-decoration: none; 
  } 
 
  .slash { 
    margin-left: 0.4em; 
    margin-right: 0.4em; 
  } 
}*/

.gray-container {
  background: $dza-gray !important;
  border-radius: 3px;
}

.select-checkbox {
  .mat-checkbox-inner-container {
    width: 22px;
    height: 22px;
    background: #ffffff;
    border: 2px solid #dfdfe8;
    border-radius: 2px;
  }
  .mat-checkbox-frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
    border-width: 0;
    border-style: solid;
  }
}

.label-point-color {
  display: block;
  width: 16px;
  height: 15px;
  border-radius: 2px;
}

.label-point-color-small {
  display: block;
  width: 15px;
  height: 11px;
  border-radius: 2px;
}

.height-100 {
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.card-wrapper {
  display: block;
  float: left;
  height: 33px;
  line-height: 33px;
  border-radius: 3px;
  width: 100%;
  max-width: 190px;
  margin-top: 0.6em;
  margin-right: 0.6em;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  text-align: center;

  //0: tacho, 1: NFC, 2: iButton, 4: RFID
  &.tacho {
    background: $dza-green;
  }

  &.rfid {
    background: #fab285;
  }

  &.iButton {
    background: #52a9e3;
  }

  &.draggable {
    cursor: grab;
  }
}

//Fix angular datetime picker in angular modal and fix datetime pickers in total
.cdk-overlay-connected-position-bounding-box {
  z-index: 99999;
}
.cdk-overlay-pane {
  z-index: 9999;
}

.mat-form-field-label-wrapper {
  overflow: visible !important;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  overflow: visible;
}

.triangle-chart-legend {
  border-color: transparent transparent red transparent;
  border-style: solid;
  border-width: 0px 12px 12px 12px;
  height: 0px;
  width: 0px;
}

.mat-form-field-infix {
  width: 100% !important;
}

.flex-grow-separator {
  flex-grow: 1;
}
