.page-header-left-action > div:nth-child(1) {
  order: 2;
}
.page-header-left-action > div:nth-child(2) {
  order: 1;
}
.page-header-left-action > div:nth-child(3) {
  order: 3;
}

.page-header-left-action .header-button {
  margin: 0 0 0 auto;
}

@media (min-width: 992px) {
  .page-header-left-action > div:nth-child(1) {
    order: 1;
  }
  .page-header-left-action > div:nth-child(2) {
    order: 2;
  }
  .page-header-left-action > div:nth-child(3) {
    order: 3;
  }
}

.page-header-wrapper .icon {
  font-size: 4.2rem;
  margin-right: 15px;
}

.page-header-wrapper h1 {
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 2.5rem;
}

.page-header-wrapper .page-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-top: 0.5rem;
  font-size: 1rem;
}
