/* libraries */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* base */

@import './base/index.css';

/* components */

@import './components/index.css';

/* utilities */
@import './tailwindcss/utilities.scss';

@import 'custom-override-bootstrap.scss';
@import 'print.scss';

@font-face {
  font-family: 'DTCO';
  src: url('../assets/fonts/DTCO.ttf') format('truetype');
}
