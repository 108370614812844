$print-border-color: #999999;
$print-text-color: #000000;
$print-background-color: transparent;

@page {
  size: 35.7cm 25cm;
  margin: 2em 0;
}

.print-visible {
  display: none;
}

.print-visible-table-cell {
  display: none;
}

@media print {
  .col {
    float: none !important;
    width: auto !important;
  } /* remove the floats for print */

  body {
    background: #fff !important;
  }

  .print-page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .print-break-before-all-browsers {
    page-break-after: always !important;
    break-after: always !important;
  }

  @supports (-moz-appearance: none) {
    .print-break-before {
      break-before: page;
    }
  }

  .header {
    display: none;
  }

  .container {
    width: 100% !important;
    max-width: 100%;
    padding: 0 3em;
  }

  .table-responsive {
    overflow-x: hidden !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }

  .print-table-width-99 {
    width: 99.2% !important;
  }

  .page-header-wrapper h1 {
    text-align: center;
  }

  .margin-top-menu {
    margin-top: 0 !important;
  }

  .print-hidden {
    display: none !important;
  }

  .print-visible {
    display: block;
  }

  .print-visible-table-cell {
    display: table-cell;
  }

  //.vehicle-park-table tbody tr{
  //  border: 1px solid #999999;
  //  border-radius: 3px;
  //}

  .vehicle-park-table {
    th:nth-child(1),
    td:nth-child(1) {
      width: 5%;
      padding-left: 1.5em;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 30%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 15%;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 5%;
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 5%;
    }

    th:nth-child(8),
    td:nth-child(8) {
      width: 20%;
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 0%;
    }

    tbody td {
      &:first-child {
        border-left: 1px solid $print-border-color !important;
      }

      &:last-child {
        border-right: 1px solid $print-border-color !important;
      }

      border-top: 1px solid $print-border-color !important;
      border-bottom: 1px solid $print-border-color !important;
      background: $print-background-color !important;

      font-style: normal;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 25px !important;

      color: $print-text-color !important;
    }
  }

  .table {
    color: $print-text-color !important;
    background: $print-background-color !important;
    border-collapse: separate;
    border-spacing: 0;
  }

  .table-headers {
    background: $print-background-color !important;

    th {
      font-family: 'Open Sans', sans-serif;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 19px !important;
      color: $print-text-color !important;
    }

    tr {
      border-left: none !important;
      border-right: none !important;
    }
  }

  .gray-datazauta-table {
    border-top: 1px solid $print-border-color !important;
    border-bottom: 1px solid $print-border-color !important;
    border-left: 1px solid $print-border-color !important;
    border-right: 1px solid $print-border-color !important;

    background: $print-background-color !important;
    padding: 1em !important;
  }

  .table > :not(caption) > * > * {
    background: $print-background-color !important;
  }
  .table > :not(caption) > * > * {
    background: $print-background-color !important;
  }

  .inner-white-table,
  .overview-by-days-table-inner-white-table {
    & > tr:first-child {
      border-top: 1px solid $print-border-color !important;
    }

    & > tr:last-child {
      border-bottom: 1px solid $print-border-color !important;
    }

    & > tr {
      border-left: 1px solid $print-border-color !important;
      border-right: 1px solid $print-border-color !important;

      & > td {
        margin: 0 !important;
        border-bottom: 1px solid $print-border-color !important;

        &.print-no-border {
          border: none !important;
          border-top: none !important;
          border-bottom: none !important;
          border-left: none !important;
          border-right: none !important;
        }
      }
    }

    tr.isOpened {
      td {
        border-bottom: none !important;
      }
    }

    background: $print-background-color !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: $print-text-color !important;
  }

  .print-table-header-around {
    & > tr {
      & > th {
        &:first-child {
          border-left: 1px solid $print-border-color !important;
        }

        &:last-child {
          border-right: 1px solid $print-border-color !important;
        }

        border-top: 1px solid $print-border-color !important;
        border-bottom: 1px solid $print-border-color !important;
      }
    }

    background: $print-background-color !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: $print-text-color !important;
  }

  .print-border-around {
    border: 1px solid $print-border-color !important;
    border-radius: 3px !important;
  }

  .vehicle-spz-label,
  .vehicle-type-label,
  .vehicle-stats-label,
  .vehicle-stats-value-wrapper,
  .vehicle-stats-value-wrapper .vehicle-stats-value-unit,
  .inner-white-table,
  .unit-wrapper {
    color: $print-text-color !important;
  }

  .print-no-border {
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .vehicle-stats-percent-wrapper {
    color: $print-text-color !important;
  }

  .print-trips-table {
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 0 !important;
      width: 1% !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 15% !important;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 15% !important;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 11% !important;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 8% !important;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 21% !important;
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 18% !important;
    }

    th:nth-child(8),
    td:nth-child(8) {
      width: 1% !important;
    }
  }

  .inner-app-driving-progress-table-wrapper {
    padding: 0 0 0 0.7em;
  }

  .driving-progress-table {
    thead th {
      position: relative !important;
      top: auto !important;
      z-index: auto !important;
      background: transparent !important;
    }

    .trip-type,
    .no-trip-type {
      background: $print-background-color !important;
      border-radius: 3px;

      &.isOpened {
        td {
          border-bottom: none !important;
        }
      }

      &.isOpenedDetail {
        td {
          border-top: none !important;
        }
      }

      td {
        &:first-child {
          border-left: 1px solid $print-border-color !important;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-right: 1px solid $print-border-color !important;
        }

        border-top: 1px solid $print-border-color !important;
        border-bottom: 1px solid $print-border-color !important;
        background: $print-background-color !important;

        font-style: normal;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 25px !important;

        color: $print-text-color !important;
      }
    }
  }
  .driving-progress-table .trip-type td:first-child,
  .driving-progress-table .no-trip-type td:first-child {
    padding-left: 1em;
  }
  .no-trip-type-separator {
    .row {
      width: 100%;
      margin: 0;
    }

    .dashed-line-wrapper {
      position: relative;
    }

    .dashed-line {
      border: 1px dashed $print-border-color !important;
    }

    .no-trip-type-text {
      color: $print-text-color !important;
    }

    .dashed-line-left {
      border: 1px dashed $print-border-color !important;
    }

    .dashed-line-right {
      border: 1px dashed $print-border-color !important;
    }
  }

  .desktop-height-50 {
    min-height: auto !important;
  }

  .print-table-header {
  }
  .print-row-cols-5 > * {
    flex: 0 0 auto !important;
    width: 20% !important;
  }

  .print-row-cols-6 > * {
    flex: 0 0 auto !important;
    width: 16.6666666667% !important;
  }

  .print-row-cols-7 > * {
    flex: 0 0 auto !important;
    width: 14.28571428571429% !important;
  }

  .print-row-cols-8 > * {
    flex: 0 0 auto !important;
    width: 12.5% !important;
  }

  .print-col-2 {
    flex: 0 0 auto !important;
    width: 16.66666667% !important;
    max-width: 16.66666667%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .print-col-4 {
    flex: 0 0 auto !important;
    width: 33.33333333% !important;
    max-width: 33.33333333%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .print-col-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 50%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .driving-progress-table-headers {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-align: right !important;

    color: $print-text-color;
  }

  .operators-registry-table .tbody-table-row {
    border-top: 1px solid $print-border-color !important;
    border-bottom: 1px solid $print-border-color !important;
    border-left: 1px solid $print-border-color !important;
    border-right: 1px solid $print-border-color !important;
    border-radius: 3px;
    background: $print-background-color !important;
    color: $print-text-color !important;
    padding: 15px;
    margin-bottom: 1rem;
  }

  .print-mt-05em {
    margin-top: 0.5em !important;
  }

  .print-mt-1em {
    margin-top: 1em !important;
  }

  .print-mt-2em {
    margin-top: 2em !important;
  }

  .print-mb-1em {
    margin-bottom: 1em !important;
  }

  .print-mb-2em {
    margin-bottom: 2em !important;
  }

  .print-full-width {
    width: 100% !important;
  }

  .mr-2 {
    margin-right: 0.5rem !important;
  }

  .table-icon {
    display: block !important;
  }

  app-tank-level-detail table {
    width: 100% !important;
    margin-bottom: 0 !important;

    th:nth-child(1),
    td:nth-child(1) {
      width: 60% !important;
    }

    td:nth-child(1) {
      padding: 0.5em 1em !important;
    }

    th:nth-child(2) {
      padding: 0 1em !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 40% !important;
      padding: 0.5rem 0.5rem !important;
    }
  }

  .note-wrapper {
    &.note-wrapper-idle {
      min-width: 120px !important;
    }

    &.note-wrapper-fuel_loss {
      min-width: 220px !important;
    }

    &.note-wrapper-refueling {
      min-width: 170px !important;
    }

    &.note-wrapper-country {
      min-width: 200px !important;
    }

    &.note-wrapper-refueling-on-engine {
      min-width: 200px !important;
    }

    .note-icon-wrapper {
      width: 32px !important;
    }

    .note-text-wrapper {
      font-size: 12px !important;
      width: calc(100% - 32px) !important;
      padding-right: 0 !important;
    }
  }
  app-odometer-table-stats table {
    th,
    td {
      width: 25% !important;
    }
  }
}
