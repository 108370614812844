.stats-box > .vehicle {
  order: 1;
}
.stats-box > .stats {
  order: 3;
  margin-top: 25px;
}
.stats-box > .stats .col {
  min-height: 4rem;
}

.stats-box > .actions {
  order: 2;
}

@media (min-width: 992px) {
  .stats-box > .stats .col {
    min-height: 0;
  }

  .stats-box > .stats {
    order: 2;
    margin-top: 0;
  }
  .stats-box > .actions {
    order: 3;
  }
}
