.vehicle-icon-box {
  display: flex;
}

.vehicle-icon-box .vehicle-icon-box-img {
  margin-right: 9px;
  padding-top: 2px;
}

.vehicle-icon-box .vehicle-icon-box-img img {
  height: 1.2rem;
  width: auto;
}

.vehicle-icon-box .vehicle-icon-box-text {
  display: inline-block;
}

.vehicle-icon-box .vehicle-icon-box-text > div {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.vehicle-icon-box .vehicle-icon-box-text .vehicle-icon-box-spz-label {
  color: #1b1a1b;
  font-size: 0.9rem;
}

.vehicle-icon-box .vehicle-icon-box-text .vehicle-icon-box-type-label {
  color: #767676;
  font-size: 0.7rem;
}

.vehicle-icon-box-smaller .vehicle-icon-box-text .vehicle-icon-box-spz-label {
  font-size: 0.8rem;
}

.vehicle-icon-box-smaller .vehicle-icon-box-img img {
  height: 1.05rem;
}

/* .working-hours-vehicles-wrapper {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      height: 20px;
      width: auto;
    } */
