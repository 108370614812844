@font-face {
  font-family: 'dza-font';
  src: url('../../assets/fonts/dza-font.ttf?5e3d5134804069e4a26cbfe318f0eff2')
      format('truetype'),
    url('../../assets/fonts/dza-font.woff?5e3d5134804069e4a26cbfe318f0eff2')
      format('woff'),
    url('../../assets/fonts/dza-font.woff2?5e3d5134804069e4a26cbfe318f0eff2')
      format('woff2');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: dza-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-down:before {
  content: '\f101';
}
.icon-angle-up:before {
  content: '\f102';
}
.icon-book:before {
  content: '\f103';
}
.icon-calendar:before {
  content: '\f104';
}
.icon-canister:before {
  content: '\f105';
}
.icon-car-battery:before {
  content: '\f106';
}
.icon-cars:before {
  content: '\f107';
}
.icon-chevron:before {
  content: '\f108';
}
.icon-clock:before {
  content: '\f109';
}
.icon-cruise-control:before {
  content: '\f10a';
}
.icon-delete:before {
  content: '\f10b';
}
.icon-download:before {
  content: '\f10c';
}
.icon-eye-slash:before {
  content: '\f10d';
}
.icon-eye:before {
  content: '\f10e';
}
.icon-fuel:before {
  content: '\f10f';
}
.icon-globe:before {
  content: '\f110';
}
.icon-info:before {
  content: '\f111';
}
.icon-location-crosshairs-slash:before {
  content: '\f112';
}
.icon-location-crosshairs:before {
  content: '\f113';
}
.icon-location-dot-slash:before {
  content: '\f114';
}
.icon-location-dot:before {
  content: '\f115';
}
.icon-lock:before {
  content: '\f116';
}
.icon-magnifying-glass:before {
  content: '\f117';
}
.icon-pen:before {
  content: '\f118';
}
.icon-print:before {
  content: '\f119';
}
.icon-retweet:before {
  content: '\f11a';
}
.icon-settings:before {
  content: '\f11b';
}
.icon-user-group:before {
  content: '\f11c';
}
.icon-user:before {
  content: '\f11d';
}
.icon-work:before {
  content: '\f11e';
}
.icon-xmark:before {
  content: '\f11f';
}
