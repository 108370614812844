.icon-green {
  color: #4db286;
}
.icon-gray {
  color: #c6c6c6;
}
.icon-dark-gray {
  color: #767676;
}
.icon-black {
  color: #000;
}
.icon-white {
  color: #fff;
}
