html,
body {
  @apply h-full;
}

body {
  @apply bg-neutral-100;
  @apply text-neutral-900;
}

body.dark {
  /*@apply bg-neutral-900;*/
  /*@apply text-neutral-50;*/
}

body {
  margin: 0 !important;
  padding: 0;
  line-height: 16.35px;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;
}

.alert {
  font-size: 0.8em;
}
